.landing-page {
  width: 100%;
  height: 100vh;
  // background-color: #f9e0e299;
  transition: all ease 0.2s;
  user-select: none;
  .landing-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #f9e0e299;
    //background-color: blue;
    transition: all ease 0.2s;
    padding-bottom: 30px;

    h1 {
      font-size: 12rem;
      font-weight: bold;
      font-family: "Urbanist", sans-serif;
      // font-family: "Gloria Hallelujah", cursive;
      color: rgb(143, 75, 75);
      margin-top: 70px;
      margin-bottom: 20px;
      // letter-spacing: 0.3rem;
    }
    p {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }
  }
  .landing-ctn2 {
    background-color: white;
    width: 100%;
    margin-top: 50px;
    // height: 502px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h3 {
      width: 40%;
      padding: 40px;
      margin-top: 40px;
      margin-left: 40px;
      font-weight: 400;
      font-size: 3rem;
      color: black;
      letter-spacing: 0.1rem;
      line-height: 1.2em;
      text-align: left;
      font-family: "Castoro", serif;
    }
    img {
      width: 600px;
      height: 500px;
      padding: 40px;
      object-fit: cover;
    }
  }
  .landing-ctn3 {
    background-color: #f9e0e299;
    width: 100%;
    margin-top: 50px;
    // height: 502px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h3 {
      width: 40%;
      padding: 40px;
      margin-top: 40px;
      margin-left: 40px;
      font-weight: 400;
      font-size: 3rem;
      color: black;
      letter-spacing: 0.1rem;
      line-height: 1.2em;
      text-align: left;
      font-family: "Castoro", serif;
    }
    img {
      width: 500px;
      height: 500px;
      padding: 40px;
      object-fit: contain;
    }
  }
}

@media (max-width: 700px) {
  .landing-page {
    .landing-ctn {
      h1 {
        font-size: 5rem;
        margin-top: 30px;
      }
      p {
        font-size: 1.3rem;
        text-align: center;
      }
    }
    .landing-ctn2 {
      margin-top: 0px;
      padding: 0px;
      h3 {
        margin: 20px 0px 20px 0px;
        width: 100%;
        font-size: 1.8rem;
        font-weight: 400;
        padding: 10px;
        text-align: center;
      }
      img {
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
        width: 100vw;
        height: auto;
      }
    }
    .landing-ctn3 {
      margin-top: 0px;
      padding: 0px;
      flex-direction: column-reverse;
      h3 {
        margin: 20px 0px 10px 0px;
        width: 100%;
        font-size: 1.8rem;
        padding: 10px;
        text-align: center;
      }
      img {
        margin: 0px;
        padding: 0px;
        margin-top: 20px;
        width: 100%;
        height: auto;
      }
    }
  }
}
