.artist-img {
  width: 130px;
  background-size: cover;
  background-position: center;
  height: 130px;
  border-radius: 15px;
  margin-top: 1rem;
  margin-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all ease 0.4s;
  z-index: 2;
  &:hover {
    .name-drop {
      opacity: 1;
    }
  }
  .name-drop {
    z-index: 2000;
    border-radius: 15px;
    transition: all ease 0.4s;
    height: 100%;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    h3 {
      transition: all ease 0.4s;
      padding: 7px;
      color: rgba(255, 255, 255, 0);
    }
    &:hover {
      h3 {
        color: white;
      }
    }
  }
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
    margin: 20px;
    .cross {
      transition: all ease 0.4s;
      opacity: 1;
    }
  }
  .cross {
    text-align: center;
    // margin-left: auto;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color: rgba(199, 64, 64, 0.849);
    color: white;
    font-weight: bold;
    transition: all ease 0.2s;
    opacity: 0;
    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
}
