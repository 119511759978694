.input1 {
  margin-top: 30px;
  transition: all ease 0.2s;
  input {
    height: 40px;
    width: 250px;
    font-size: 1rem;
    padding-left: 10px;
    border-radius: 5px;
    outline: none;
    border: solid black 1px;
    background-color: #f3f3f4;
    transition: all ease 0.2s;

    &:hover {
      border-color: rgba(234, 76, 137, 0.4);
      box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
    }
  }
  button {
    box-sizing: border-box;
    color: white;
    height: 40px;
    padding: 10px 16px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    border: none;
    border-radius: 8px;
    margin-left: 10px;
    outline: none;
    background: #ea4c89;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border: solid transparent 1px;
    transition: all ease 0.2s;
    &:hover {
      background-color: #f3f3f4;
      color: #ea4c89;
      border: solid black 1px;
    }
  }
}

@media (max-width: 600px) {
  .input1 {
    width: 300px;
    input {
      width: 100%;
      height: 50px;
    }
    button {
      margin-top: 10px;
      width: 100%;
      margin: 20px auto;
      height: 50px;
    }
  }
}
