.signup2 {
  width: 1000px;
  height: 800px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  margin: auto;
  margin-top: 50px;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  cursor: default;
  .section1 {
    width: 50%;
    height: 100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    transform: scaleX(-1);
  }
  .section2 {
    transition: all 1s ease;

    width: 50%;
    height: 100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    // background-color: rgb(224, 222, 222);
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .inner-section2 {
      width: 70%;
      height: 70%;
      text-align: center;
      .login-btn {
        all: revert;
        width: 300px;
        height: 40px;
        padding-left: 10px;
        margin-top: 30px;
        font-size: 1.1rem;
        position: relative;
        left: 3px;
        outline: none;
        border: none;
      }

      .login-btn-standard {
        background-color: rgb(110, 110, 227);
        color: white;
        &:hover {
          cursor: pointer;
          border: solid white 1px;
          background-color: white;
          color: rgb(110, 110, 227);
          border: solid 1px rgb(110, 110, 227);
        }
      }

      .robot-icon {
        width: 80px;
        height: 80px;
        margin: auto;
      }
      h2 {
        margin-top: 20px;
        color: black;
      }
      p {
        color: black;
        margin-top: 15px;
      }
      .input-ctn {
        margin-top: 10px;
        .check-box-recover-ctn {
          width: 300px;
          height: 40px;
          margin: 0 auto;
          display: flex;
          margin-top: 0px;
          justify-content: space-between;
          align-items: center;
          // background-color: blue;
          input,
          label,
          a {
            all: revert;
            font-size: 0.8rem;
            font-weight: 200;
          }
          input {
            position: relative;
            top: 2px;
            &:hover {
              cursor: pointer;
            }
          }
          label {
            color: black;
          }
          a {
            color: rgb(89, 89, 215);
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .input-grp {
        width: 300px;
        height: 40px;
        margin: 0 auto;
        margin-top: 30px;
        position: relative;
        input {
          all: revert;
          font-family: "Montserrat", sans-serif;
          font-size: 1.1rem;
          border: black;
          border-radius: 7px;
          height: 100%;
          width: 100%;
          background-color: rgba(181, 187, 197, 0.656);
          padding-left: 10px;
          transition: all ease 0.5s;
          &:focus {
            background-color: rgb(220, 219, 219);
          }
          // outline: none !important;
          // border: 1px solid rgb(0, 110, 255);
          // box-shadow: 0 0 10px #719ece;
        }
        .icon {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 10px;
          right: 7px;
          // margin-left: auto;
          display: block;
        }
      }
    }
    .dont-have-account {
      text-align: center;
      position: relative;
      margin-top: 40px;
      p {
        font-size: 0.9rem;
        font-weight: 200;
      }
      a {
        all: revert;
        font-size: 0.9rem;
        font-weight: 200;
        color: rgb(89, 89, 215);
        border-bottom: solid 1px rgba(89, 89, 215, 0);
        transition: all ease 0.61s;
        &:hover {
          cursor: pointer;
          border-bottom: 1px solid rgba(89, 89, 215, 1);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .signup2 {
    width: 100%;
    border-radius: 0px;
    margin-top: 0px;
    .section2 {
      align-items: flex-start;
      width: 100vw;
      padding-top: 20px;
      text-align: center;
      .inner-section2 {
        margin-top: 0px;
        text-align: center;
        padding: 0px;
        .robot-icon {
          margin-top: 0px;
          padding-top: 0px;
        }
      }
    }
    .section1 {
      display: none;
      transition: none;
      background-color: red;
    }
  }
}
