.navbar {
  height: 50px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  transition: all ease 0.3s;
  p {
    font-family: "Gloria Hallelujah", cursive;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;
  }
  img {
    object-fit: contain;
    height: 45px;
    width: 45px;
    transition: all ease 0.3s;
    margin-left: 10px;
    background-color: orange;
    border-radius: 50px;
    padding: 10px;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
  ul {
    display: flex;
    list-style-type: none;
    display: flex;
    align-items: center;
    li {
      margin: 0 5px;
      transition: all ease 0.3s;
    }
    a {
      transition: all ease 0.3s;
      color: black;
      text-decoration: none;
      cursor: pointer;
      text-transform: uppercase;
      padding: 10px;
      &:hover {
        cursor: pointer;
        background-color: rgba(148, 161, 174, 0.727);
        color: white;
      }
    }
  }
  .hamburger-icon {
    // border: solid black 1px;
    display: none;
  }
}

@media (max-width: 700px) {
  .navbar {
    padding: 0px;
    .hamburger-icon {
      display: flex;

      justify-content: center;
      align-items: center;
      margin-right: 20px;
      height: 40px;
      width: 40px;
      border-radius: 5px;
      img {
        background: none;
        border-radius: 0px;
        object-fit: contain;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    ul {
      display: none;
    }
    .vertical-menu {
      display: block;
      position: absolute;
      top: 50px;
      left: 0px;
      background: linear-gradient(145deg, #f0f0f0, #cacaca);
      box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
      z-index: 2000;
      width: 100vw;
      a {
        background-color: none;
      }
      li {
        text-align: center;
        margin: 10px 0px;
        &:hover {
          // background-color: lightslategrey;
        }
      }
    }
  }
}
